import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

const API_URL = "https://workers.pavanagrawal.workers.dev";

export default function App() {
  const data = useState(null);
  const [list, setList] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const createPost = async () => {
    const response = await fetch(`${API_URL}/post`, {
      method: "POST",
      body: JSON.stringify({
        username: name,
        title,
        content,
      }),
    });
    await loadData();
  };

  const upvotePost = async (uuid) => {
    const response = await fetch(`${API_URL}/upvote/${uuid}`, {
      method: "POST",
    });
    await loadData();
  };

  useEffect(async () => {
    let mounted = true;
    await loadData();
    return () => (mounted = false);
  }, []);

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        {list.map((listData) => {
          return (
            <Card style={{ marginTop: "15px" }} sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {listData.username}
                </Typography>
                <Typography variant="h5" component="div">
                  {listData.title}
                </Typography>
                <Typography variant="body2">{listData.content}</Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => upvotePost(listData.uuid)} size="small">
                  Upvote Post - {listData.upvotes}
                </Button>
              </CardActions>
            </Card>
          );
        })}
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography style={{ marginTop: "50px" }} variant="h6">
            Make your mark! Add some content to this website.
          </Typography>
          <div>
            <TextField
              onChange={(event) => setName(event.target.value)}
              required
              label="Name"
            />
            <TextField
              onChange={(event) => setTitle(event.target.value)}
              label="Post Title"
            />
            <TextField
              onChange={(event) => setContent(event.target.value)}
              multiline
              label="Post Content"
            />
            <Button onClick={() => createPost()}>Post</Button>
          </div>
        </Box>
      </Box>
    </Container>
  );

  async function loadData() {
    let data = await fetch(`${API_URL}/posts`);
    let parsedData = await data.json();
    setList(parsedData.posts);
  }
}
